<template>
  <div class="authentication-detail">
    <app-header :title="title" :isShowBack="true"></app-header>
    <div>
      <app-detail :opt="opt"></app-detail>
    </div>

    <div v-if="ShortLong">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="订单信息" name="first">
          <el-table :data="orderDataList" style="width: 100%">
            <el-table-column
              v-for="item in column1"
              :key="item.prop"
              :prop="item.prop"
              :label="item.label"
            ></el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="合同信息" name="second">
          <el-table :data="payList" style="width: 100%">
            <el-table-column
              v-for="item in column3"
              :key="item.prop"
              :prop="item.prop"
              :label="item.label"
              :on="item.on"
            >
              <template slot-scope="scope">
                <a class="row-nav" v-if="item.on" @click="item.on(scope.row)">查看</a>
                <span v-else>{{scope.row[item.prop]}}</span>
                <a
                  class="row-nav row-nav-more"
                  v-if="item.on && ['0','2','3','4', '5'].indexOf(scope.row.state) == -1"
                  @click="onTermination(scope.row)"
                >解约</a>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this;
    return {
      rentalStyle: "",
      longList: {},
      title: "退款详情",
      ShortLong: false,
      opt: {
        form:[
      {
        label: "退款信息",
        type: "TitleName"
      },
      {
        label: "退款单号",
        key: "refundNo",
        isCol50: true
      },
      {
        label: "退款状态",
        key: "state",
        isCol50: true
      },
      {
        label: "退款总金额（元）",
        key: "refundFee",
        isCol50: true
      },
      {
        label: "物业费应退（元）",
        key: "checkoutPropertyFee",
        isCol50: true
      },
      {
        label: "租金应退（元）",
        key: "checkoutRentalFee",
        isCol50: true
      },
      {
        label: "退款到账时间",
        key: "successTime",
        isCol50: true
      },
      {
        label: "押金应退（元）",
        key: "checkoutDepositFee",
        isCol50: true
      },
      {
        label: "扣款金额（元）",
        key: "deductionFee",
        isCol50: true
      },
      // {
      //   label: "平台批准退款时间",
      //   key: "approveTime",
      //   isCol50: true
      // },
      {
        label: "房客信息",
        type: "TitleName"
      },

      {
        label: "房源信息",
        key: "address",
        isCol50: true
      },
      {
        label: "租赁模式",
        key: "rentalStyleStr",
        isCol50: true
      },
      {
        label: "房客姓名",
        key: "tenantRealname",
        isCol50: true
      },
      {
        label: "入住起始日期",
        key: "rentStartDate",
        filters: {
               key: "isShow",
               value: "1"
        },
        isCol50: true
      },
      {
        label: "入住起始时间",
        key: "rentStartDate",
        filters: {
              key: "rentalStyle",
              value: "3"
            },
        isCol50: true
      },
      {
        label: "房客手机号码",
        key: "tenantPhone",
        isCol50: true
      },
      {
        label: "入住截止日期",
        key: "rentEndDate",
        filters: {
               key: "isShow",
               value: "1"
        },
        isCol50: true
      },
      {
        label: "入住截止时间",
        key: "rentEndDate",
        filters: {
              key: "rentalStyle",
              value: "3"
            },
        isCol50: true
      },
      {
        label: "租金（元）",
        key: "rentalFee",
        filters: {
               key: "isShow",
               value: "1"
        },
        isCol50: true
      },
      // {
      //   label: "物业费（元）",
      //   key: "propertyFee",
      //   isCol50: true
      // },
      {
        label: "押金（元）",
        key: "deposit",
        filters: {
               key: "isShow",
               value: "1"
        },
        isCol50: true
      },
      {
        label: "退房日期",
        key: "quitDate",
        filters: {
               key: "isShow",
               value: "1"
        },
        isCol50: true
      },
      {
        label: "退房时间",
        key: "quitDate",
        filters: {
               key: "rentalStyle",
               value: "3"
        },
        isCol50: true
      },
      {
        label: "入住时长",
        key: "hours",
        filters: {
               key: "rentalStyle",
               value: "3"
        },
        isCol50: true
      },
      {
        label: "提交申请退房时间",
        key: "quitCreateDate",
        isCol50: true
      },
      {
        label: "房东同意退房时间",
        key: "landlordAgreeQuitDate",
        isCol50: true
      }
    ],
        get(opt) {
          let dto = {
            id: _this.$route.params.id
          };
          console.log(_this.$route.query.rentalStyle);
          if (_this.$route.query.rentalStyle == 1 || _this.$route.query.rentalStyle == 3) {
            _this.opt.form.splice(4, 1);
            // _this.opt.form.splice(16, 1);
          } else if (_this.$route.query.rentalStyle == 0) {
            _this.opt.form.splice(4, 1, {
              label: "物业费应退（元）",
              key: "checkoutPropertyFee",
              isCol50: true
            });
            _this.opt.form.splice(17, 1, {
              label: "物业费（元）",
              key: "propertyFee",
              isCol50: true
            });
          }
          _this
            .post("finance-service/refundOperation/detail", dto)
            .then(data => {
              let listdata = [];
              listdata.push(data);
              listdata.forEach(item => {
                item.state = ["退款中","退款中","退款成功","退款异常","退款取消","退款失败"][item.state];
                if (_this.$route.query.rentalStyle == 0) {
                  item.propertyFee = item.propertyFee == null ? "0.00" : item.propertyFee;
                  item.checkoutPropertyFee = item.checkoutPropertyFee == null ? "0.00" : item.checkoutPropertyFee;
                }
                if (item.rentalStyle == 3){
                  item.rentStartDate = moment(item.rentStartDate).format("YYYY-MM-DD HH:mm:ss");
                  item.rentEndDate = moment(item.rentEndDate).format("YYYY-MM-DD HH:mm:ss");
                  item.quitDate = moment(item.quitDate).format("YYYY-MM-DD HH:mm:ss");
                }else{
                item.rentStartDate = moment(item.rentStartDate).format("YYYY-MM-DD");
                item.rentEndDate = moment(item.rentEndDate).format("YYYY-MM-DD");
                item.quitDate = moment(item.quitDate).format("YYYY-MM-DD");
                }
                item.refundFee = item.checkoutSum == null ? "0.00" : parseFloat(item.checkoutSum).toFixed(2);
                if (item.rentalStyle !== "1") {
                  item.rentalFee = item.rentalFee == null ? "0.00" : parseFloat(item.rentalFee).toFixed(2);
                } else {
                  item.rentalFee = item.shortRentFee == null ? "0.00" : parseFloat(item.shortRentFee).toFixed(2);
                }
                item.checkoutDepositFee = item.checkoutDepositFee == null ? "0.00" : parseFloat(item.checkoutDepositFee).toFixed(2);
                item.deductionFee = item.deductionFee == null ? "0.00" : item.deductionFee;
                item.checkoutRentalFee = item.checkoutRentalFee == null ? "0.00" : parseFloat(item.checkoutRentalFee).toFixed(2);
                item.deposit = item.deposit == null ? "0.00" : parseFloat(item.deposit).toFixed(2);
                item.isShow = item.rentalStyle == 3 ? 0 : 1
                item.rentalStyleStr = ["长租", "短租", "长租","钟点房"][item.rentalStyle];

                //计算入住时长
                var t1 = new Date(item.rentStartDate);
                var t2 = new Date(item.rentEndDate);
                var hours = t2.getTime() - t1.getTime();
                var leave = hours%(24*3600*1000);
                var h = parseInt(leave/(3600*1000));
                var h_leave = leave%(3600*1000);
                var min = parseInt(h_leave/(60*1000));
                var min_leave = h_leave%(60*1000);
                var sec = parseInt(min_leave/1000);
              // item.hours = t1
                if(h==0 && min==0 && sec==0){
                  item.hours = '--'
                }else{
                  item.hours = h + '小时' + min + '分' +  sec + '秒'
              }
              });
              opt.cb(listdata[0]);
            });
        }
      },
      orderDataList: [],
      activeName: "first",
      column1: [
        {
          prop: "orderNo",
          label: "订单编号"
        },
        {
          prop: "payRentalTerm",
          label: "应缴日期"
        },
        {
          prop: "payDate",
          label: "实缴日期"
        },
        {
          prop: "orderName",
          label: "订单名称"
        },
        {
          prop: "rentalFee",
          label: "租金"
        },
        {
          prop: "depositFee",
          label: "押金"
        },
        {
          prop: "otherFee",
          label: "物业费"
        },
        {
          prop: "electric",
          label: "电费"
        },
        {
          prop: "coldWater",
          label: "冷水费"
        },
        {
          prop: "hotWater",
          label: "热水费"
        },
        {
          prop: "orderAmount",
          label: "总金额"
        },
        {
          prop: "isDeduction",
          label: "是否抵扣押金"
        },
        {
          prop: "depositAmount",
          label: "抵扣押金金额"
        },
        {
          prop: "payState",
          label: "订单状态"
        }
      ],
      payList: [],
      column3: [
        {
          prop: "createdDate",
          label: "签约时间"
        },
        {
          prop: "id",
          label: "合同编号"
        },
        {
          prop: "apartmentName",
          label: "房屋详情"
        },
        {
          prop: "count",
          label: "合同期限"
        },
        {
          prop: "endDateStr",
          label: "到期日期"
        },
        {
          prop: "rentalFee",
          label: "月租金"
        },
        {
          prop: "stateStr",
          label: "合同状态"
        }
      ]
    };
  },
  created() {
    
  },
  activated: function() {
    console.log(this.$route.query,'this.$route.query---');
    this.contractId = this.$route.query.contractId || "";
    this.userId = this.$route.query.userId || "";
    this.roomId = this.$route.query.roomId || "";
    this.orderType = this.$route.query.orderType == 'undefined'?'': this.$route.query.orderType|| "";
    this.rentalStyle = this.$route.query.rentalStyle || "";
    console.log("sale-detail activated!!");
    if (this.rentalStyle == "0" || this.rentalStyle == "3") {
      this.ShortLong = true;
      this.getOrdeData(this.userId,this.contractId,this.roomId,this.orderType);
      this.getData(this.contractId);
    } else {
      this.ShortLong = false;
    }
    this.opt.form = [
      {
        label: "退款信息",
        type: "TitleName"
      },
      {
        label: "退款单号",
        key: "refundNo",
        isCol50: true
      },
      {
        label: "退款状态",
        key: "state",
        isCol50: true
      },
      {
        label: "退款总金额（元）",
        key: "refundFee",
        isCol50: true
      },
      {
        label: "物业费应退（元）",
        key: "checkoutPropertyFee",
        isCol50: true
      },
      {
        label: "租金应退（元）",
        key: "checkoutRentalFee",
        isCol50: true
      },
      {
        label: "退款到账时间",
        key: "successTime",
        isCol50: true
      },
      {
        label: "押金应退（元）",
        key: "checkoutDepositFee",
        isCol50: true
      },
      {
        label: "扣款金额（元）",
        key: "deductionFee",
        isCol50: true
      },
      // {
      //   label: "平台批准退款时间",
      //   key: "approveTime",
      //   isCol50: true
      // },
      {
        label: "房客信息",
        type: "TitleName"
      },

      {
        label: "房源信息",
        key: "address",
        isCol50: true
      },
      {
        label: "租赁模式",
        key: "rentalStyleStr",
        isCol50: true
      },
      {
        label: "房客姓名",
        key: "tenantRealname",
        isCol50: true
      },
      {
        label: "入住起始日期",
        key: "rentStartDate",
        filters: {
               key: "isShow",
               value: "1"
        },
        isCol50: true
      },
      {
        label: "入住起始时间",
        key: "rentStartDate",
        filters: {
              key: "rentalStyle",
              value: "3"
            },
        isCol50: true
      },
      {
        label: "房客手机号码",
        key: "tenantPhone",
        isCol50: true
      },
      {
        label: "入住截止日期",
        key: "rentEndDate",
        filters: {
               key: "isShow",
               value: "1"
        },
        isCol50: true
      },
      {
        label: "入住截止时间",
        key: "rentEndDate",
        filters: {
              key: "rentalStyle",
              value: "3"
            },
        isCol50: true
      },
      {
        label: "租金（元）",
        key: "rentalFee",
        filters: {
               key: "isShow",
               value: "1"
        },
        isCol50: true
      },
      // {
      //   label: "物业费（元）",
      //   key: "propertyFee",
      //   isCol50: true
      // },
      {
        label: "押金（元）",
        key: "deposit",
        filters: {
               key: "isShow",
               value: "1"
        },
        isCol50: true
      },
      {
        label: "退房日期",
        key: "quitDate",
        filters: {
               key: "isShow",
               value: "1"
        },
        isCol50: true
      },
      {
        label: "退房时间",
        key: "quitDate",
        filters: {
               key: "rentalStyle",
               value: "3"
        },
        isCol50: true
      },
      {
        label: "入住时长",
        key: "hours",
        filters: {
               key: "rentalStyle",
               value: "3"
        },
        isCol50: true
      },
      {
        label: "提交申请退房时间",
        key: "quitCreateDate",
        isCol50: true
      },
      {
        label: "房东同意退房时间",
        key: "landlordAgreeQuitDate",
        isCol50: true
      }
    ]
    },
  methods: {
    // 获取订单信息
    getOrdeData(userId,contractId,roomId,orderType) {
      let dto = {
        contractId: contractId,
        bizId: contractId,
        roomId: roomId,
        userId: userId,
        orderType: orderType
      };
      this.post("finance-service/leaseOrder/searchLeaseOrder", dto).then(res => {
        res.forEach(item => {
          for (var key in item) {
            if (key == "payState") {
              //1： 未支付 2：支付中 3：支付成功 4：支付失败 5: 订单取消 6：订单失效（首单30分钟未支付）
              item[key] = [
                "未支付",
                "支付中",
                "已支付",
                "支付失败",
                "订单取消",
                "订单失效"
              ][item.payState - 1];
            }
            if(key == 'isDeduction') {
              item[key] = ['否','是'][item.isDeduction]
            }
          }
          item.depositAmount = item.depositAmount ? item.depositAmount:'--'
          if(item.waterElectrics.length > 0) {
            console.log(item.waterElectrics[0],'item.waterElectrics[0].electric---');
            item.electric = item.waterElectrics[0].electric ? item.waterElectrics[0].electric : '0.00'
            item.coldWater = item.waterElectrics[0].coldWater ? item.waterElectrics[0].coldWater : '0.00'
            item.hotWater = item.waterElectrics[0].hotWater ? item.waterElectrics[0].hotWater : '0.00'
          } else {
            item.electric = '--'
            item.coldWater = '--'
            item.hotWater = '--'
          }

        });
        this.orderDataList = res;
      });
    },
    // 获取合同信息
    getData(id) {
      let dto = {
        contractId: id,
        bizId: id
      };
      this.post("tenant-service/leasecontract/selectTenantContract", dto).then(
        res => {
          res.forEach(item => {
            item.stateStr = ["待支付","签约成功","签约失败","合同到期","已解约","强制解约"][item.state];
            // var nowYear = new Date().getFullYear();
            // var endDateValue = Number(moment(item.endDate).format("YYYY"));
            // if (endDateValue > nowYear) {
            //   item.count =
            //     moment(item.endDate).month() +
            //     12 -
            //     moment(item.startDate).month();
            // } else {
            //   item.count =
            //     moment(item.endDate).month() - moment(item.startDate).month();
            // }
            item.startDate = moment(item.startDate).format("YYYY-MM-DD");
            item.endDate = moment(item.endDate).format("YYYY-MM-DD");
          if (
            item.startDate === moment(item.startDate).startOf("month").format("YYYY-MM-DD")
          ) {
            item.count = moment(item.endDate).diff(item.startDate, "month") + 1;
          } else {
            if (moment(item.startDate).format("DD") == "31" && moment(item.endDate).format("DD") == "30") {
              item.count = moment(item.endDate).diff(item.startDate, "month") + 1;
            } else {
              item.count = moment(item.endDate).diff(item.startDate, "month");
            }
          }
            item.endDateStr = moment(item.endDate).format("YYYY-MM-DD");
            item.apartmentName = `${item.roomInfo.cityName}${item.roomInfo.apartmentName}房源${item.roomInfo.buildNo}${item.roomInfo.roomNo}房`
            for (var key in item) {
              if (key == "payState") {
                item[key] = item.payState == 3 ? "已缴费" : "未缴费";
              }
              if (key == "gender" && item[key]) {
                item[key] = ["男", "女"][item[key] - 1];
              }
            }
          });
          this.payList = res;
        }
      );
    },
    handleClick(tab, event) {
      switch (tab.paneName) {
        case "tab0":
          break;
        case "tab1":
          break;
        default:
          break;
      }
    }
  }
};
</script>
<style lang="scss">
.authentication-detail {
  .li_title {
    // width: 100%;
    padding: 20px 30px 0 30px;
    border: 1px dashed #333333;
    position: relative;
    top: 0;
    left: 0;
    .lis_span {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      position: absolute;
      top: -12px;
      left: 20px;
      padding: 0 10px;
      background-color: #fff;
    }
  }
}
</style>

